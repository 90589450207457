import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { BodyRenderer, Layout } from 'components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
// import Helmet from 'react-helmet'
import styled from 'styled-components';

const Image = styled(Img)`
  width: 100%;
  height: 50vh;
`

const useStyles = makeStyles(theme => ({
  newsDisplay: {
    boxShadow: '1px 1px 57px rgba(1, 1, 1, 0.15)',
    backgroundColor: '#ffffff',
    padding: '3%',
    margin: '0 auto',
    marginTop: '-60px',
    marginBottom: '20px'
  },
  mainContainer: {
    position: 'relative'
  },
  heading: {
    fontFamily: 'Libre Baskerville',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 50
  },
  span: {
    textTransform: 'uppercase',
    margin: 10,
    color: '#b7b7b7',
    fontSize: '18px'
  }
}))

function News (props) {
  const classes = useStyles()
  return (
    <>
      {/* <Helmet htmlAttributes={{ lang: props.pageContext.locale }} /> */}
      <Box>
        <Image fluid={props.data.contentfulNews.coverPhoto.fluid} />
      </Box>

      <Container maxWidthXl className={classes.mainContainer}>
        <Box className={classes.newsDisplay}>
          <Typography className={classes.heading} variant="h2">
            {props.data.contentfulNews.title}
          </Typography>
          <Typography paragraph align="center">
            <span className={classes.span}>Created by:</span>
            <span className={classes.span}>
              {props.data.contentfulNews.author}
            </span>
            <span className={classes.span}>
              {props.data.contentfulNews.date}
            </span>
          </Typography>
          <Typography component="div">
            <BodyRenderer json={props.data.contentfulNews.newsContent.json} />
          </Typography>
        </Box>
      </Container>
    </>
  )
}

export default News

export const newsQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulNews(slug: { eq: $slug }) {
      id
      slug
      title
      node_locale
      mainHeading
      newsContent {
        json
      }
      coverPhoto {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid
        }
      }
      author
      date(formatString: "DD.MM.YYYY")
    }
  }
`
